

.pharmacy-divider .cls-1 {
	fill: none
}

.pharmacy-divider .cls-3,
.pharmacy-divider .cls-6 {
	fill: #fff
}

.pharmacy-divider .cls-3 {
	stroke: #adafb0;
	stroke-linejoin: round;
	stroke-width: 1.5px
}

.pharmacy-divider .cls-4 {
	fill: #929495
}

.pharmacy-divider .cls-7 {
	fill: #47abc6
}

