@import url("./Fonts/BD_FSAlbert_Fonts/font.css");

body {
  font-family: FSAlbertArabicRegular, Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.h1,
h1,
.h2,
h2,
.h3,
h3 {
  font-family: FSAlbertArabicLight, Arial, Helvetica, sans-serif;
}

.lead,
p {
  font-family: FSAlbertArabicRegular, Arial, Helvetica, sans-serif;
}

p.font-weight-semi-bold,
.font-weight-semi-bold {
  font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
}

.small {
  font-family: FSAlbertArabicRegular, Arial, Helvetica, sans-serif;
  font-size: 70% !important;
}

.font-weight-bold {
  font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
}

.display-5 {
  font-size: 2.9rem;
}

.hr-line {
  border-bottom: 1px solid #4B4B4B;
}

.text-uppercase {
  letter-spacing: 0.1em;
}

/* Subpage backgroundcolor */
.bg-wild-sand {
  background-color: #f6f4f4;
}

/* Subpages */
@media(min-width: 1200px) and (max-width: 1350px){
  .subpage-content {
    padding-left: 3rem !important;
  }
}

.infoCard {
  margin-bottom: 6.25rem;
}

/*Font Colors*/
.grey-title,
.gray-title {
  color: #4B4B4B;
}

.text-gray {
  color: #aaa;
}


#chat-btn a {
  color: white;
  font-weight: 600;
}

@media(min-width: 1700px) {
  .padding-text {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
  
.btn:hover {
  /* color: transparent !important; */
  text-decoration: none;
}

/* Dropdown toggle icon */

/* End */

.sol-market-text {
  color: black;
}


/* React Overlay for modal */

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100;
}


/* Netscore Modal */
/* .rc-slider-dot-active,
.rc-slider-handle,
.rc-slider-handle:hover {
  border-color: #004593;
}

.rc-slider-dot-active,
.rc-slider-handle-click-focused:focus {
  background-color: #004593;
}

.rc-slider-handle{
  border: solid 2px #004593 !important;
  width: 1.1rem;
  height: 1.1rem;
  margin-top: -8px;
}

.rc-slider-mark-text,
.rc-slider-mark-text-active {
  color: black;
  font-size: .75rem;
}

.rc-slider-track {
  background-color: #004593;
}

.rc-slider-handle:active {
  border-color: #004593;
  box-shadow: 0 0 5px #004593;
}

.rc-slider-step {
  border: 1px solid black;
}

.rc-slider-dot {
  border: 1px solid black;
  height: 1rem;
  width: 1rem;
  bottom: -6px;
  margin-left: -8px;
} */


/* ---------------------------------------------------------- */

/* For text heights */
.text-height-0 {
  line-height: 1 !important;
}
.text-height-1 {
  line-height: 1.5 !important;
}
.text-height-2 {
  line-height: 2 !important;
}
.text-height-3 {
  line-height: 2.5 !important;
}
.text-height-4 {
  line-height: 3 !important;
}
.text-height-5 {
  line-height: 3.5 !important;
}
/* End */


/* Donation Tracker Responsiveness (hide on mobile) */

@media screen and (orientation:landscape)
  and (min-device-width: 500px) 
  and (max-device-width: 900px) {
    .donation-tracker-wrapper {
      display: none;
      visibility: hidden;
    }
  }

@media (max-width: 1600px) {
  .donation-tracker-wrapper h5,
  .donation-tracker-wrapper .h4,
  .donation-tracker-wrapper a,
  .donation-tracker-wrapper button {
    font-size: 95%;
  }

  .donation-background {
    padding: .5rem;
  }
}

@media(max-width: 767px) {
  .donation-tracker-wrapper {
    display: none;
    visibility: hidden;
  }
}


/* Footer */

button.ot-sdk-show-settings {
  color:#ffffff !important;
  border:0 !important;
  font-size: 1em !important;
  padding: 0 !important;
  background-color: #014695;
  font-weight: bold;
  }

  button.ot-sdk-show-settings:hover {
  background:#004593 !important;
  color: #99b5d4 !important;
  border:0 !important;
  font-size: 1em !important;
  padding: 0 !important;
  text-decoration: none;
  }


  
/*End Footer */

/* Marketo CSS */
@media only screen and (max-width: 480px), only screen and (max-device-width: 480px), only screen and (max-device-height:480px){
  .mktoForm,.mktoForm *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-moz-box-sizing:border-box;}
  .mktoLabel.mktoHasWidth {height: inherit !important;}
  .mktoForm .mktoGutter,.mktoForm .mktoOffset{display:initial !important}
  .mktoForm .mktoFormCol .mktoLabel{text-align:left;width:initial !important}
  .mktoForm .mktoFormCol{float:initial !important}
  .mktoForm .mktoFieldWrap{float:initial !important}
  .mktoForm fieldset{padding:initial !important}
  .mktoForm input[type=url],.mktoForm input[type=text],.mktoForm input[type=date],.mktoForm input[type=tel],.mktoForm input[type=email],.mktoForm input[type=number],.mktoForm textarea.mktoField,.mktoForm select.mktoField{width:100% !important;height:initial !important;line-height:initial !important;font-size:initial !important}
  .mktoForm select.mktoField{height:auto}
  .mktoForm .mktoFormRow .mktoField{clear:left}
  .mktoForm .mktoFormRow .mktoFormCol{clear:both}
  .mktoForm .mktoRadioList,.mktoForm .mktoCheckboxList{width:initial !important}
  .mktoForm .mktoFormRow .mktoRequiredField .mktoAsterix{float:left;padding-left:initial !important;padding-right:5px}
  .mktoModal .mktoModalContent{padding:initial !important}
  .mktoModal .mktoModalClose{right:initial !important}
  .mktoButtonRow {width:100%; margin-top:0.15rem !important;}
  .mktoButtonWrap {margin-left:0px; float:right;}
  .mktoForm .mktoButton {margin-left: 0 !important;}
  .mktoButton {padding:0.5rem !important;}
  }

/* For Marketo forms responsiveness CSS */
.mktoForm, .mktoForm .mktoFieldWrap, .mktoForm .mktoHtmlText, .mktoForm input, .mktoLogicalField .mktoCheckboxList {width:100% !important;}
@media only screen and (min-width:480px) {
.mktoFormCol:first-child:nth-last-child(2), .mktoFormCol:first-child:nth-last-child(2) ~ .mktoFormCol {width: 100% !important;}
.mktoFormCol:first-child:nth-last-child(3), .mktoFormCol:first-child:nth-last-child(3) ~ .mktoFormCol {width: 50% !important;}
.mktoFormCol:first-child:nth-last-child(4), .mktoFormCol:first-child:nth-last-child(4) ~ .mktoFormCol {width: 33.3333% !important;}
.mktoFormCol:first-child:nth-last-child(3) ~ .mktoFormCol {padding-left: 0.3em !important;}
}

  @media only screen and (max-width:480px) {.mktoFormCol {width:100% !important;}}
  .mktoAsterix{display:none !important;}
  .mktoForm .mktoGutter {display:none !important;}
  .mktoButtonWrap {margin-left:0 !important;}
  .mktoForm input[type=text], .mktoForm input[type=url], .mktoForm input[type=email], .mktoForm input[type=tel], .mktoForm input[type=number], .mktoForm input[type=date], .mktoForm textarea.mktoField, .mktoForm select.mktoField {padding: 0.3em; width: 100% !important;}
  .mktoForm {clear: both; margin-bottom: 0.3em;}
  .mktoForm .mktoRadioList, .mktoForm .mktoCheckboxList {float: left !important; width: auto !important;}
  .mktoForm .mktoRadioList > label, .mktoForm .mktoCheckboxList > label {display: inherit !important;}
  .mktoForm input[type="radio"], .mktoForm input[type="checkbox"] {width: auto !important;}
  .mktoForm fieldset legend {margin: inherit !important;}

/* Marketo Modal Form CSS */
 .mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
  color:#fff;
  border: none;
  padding:0.4em 1em;
  font-size:1em;
  background-color: #f26925 !important;
  float: right;
  background-image: -webkit-linear-gradient(top, #f26925, #f26925) !important;
  background-image: -moz-linear-gradient(top, #f26925, #f26925) !important;
  background-image: linear-gradient(to bottom, #f26925, #f26925) !important;
  }
  .mktoForm .mktoButtonWrap.mktoSimple .mktoButton:hover {
  border:2px solid #02519d;

  }
  .mktoForm .mktoButtonWrap.mktoSimple .mktoButton:focus {
  outline:none;
  border:1px solid #447f19;
  }
 
  /* New Marketo forms css */
  form {
    background: #fff;
    padding: 10px;
    width: 100% !important;
  }
  
  .mktoForm .mktoFormRow {
      clear: none !important;
      width: 100%;
      margin: 0;
  }

  .mktoForm .mktoFormCol,
  .mktoForm .mktoFieldWrap {
    width: 100% !important
  }
  
  .mktoForm .mktoGutter {
    float: left;
    height: .5em;
  }
  
  .mktoField {
    display: block;
    width: 100% !important;
    height: 40px;
    padding: 12px 12px !important;
    font-size: 1.6rem !important;
    line-height: 1.428571429;
    color: #000000;
    /* vertical-align: middle; */
    background-color: #ffffff;
    border: 0.2px solid lightgray;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    font-family: 'Mulish', sans-serif !important;
    font-weight: 100;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
    
  .mktoField:focus {
    border-color: #d4d6d7;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .mktoForm .mktoButtonWrap .mktoButton {
    color: #fff;
    border: none !important;
    border-radius: 0px;
    background: #f27707 !important;
    font-family: 'Mulish', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 600;
    margin: 25px 0;
    padding: .5rem;
  }

  .mktoField.mktoTextField.mktoHasWidth,
  .mktoFieldWrap .mktoField.mktoTextField.mktoHasWidth.mktoValid,
  .mktoField.mktoTextField.mktoHasWidth.mktoRequired,
  .mktoField.mktoEmailField.mktoHasWidth.mktoRequired {
    font-family: 'Mulish', sans-serif !important;
    font-size: 1.45rem !important;
  }
  
  .mktoButtonWrap {
    margin-left: 0 !important;
    display:block;
    width:100%;
  }
  
  .mktoButtonRow {
      display: inline-block;
      position: relative;
      width: 100%;
  }
  
  label {
    font-weight: 100;
    font-family: 'Mulish', sans-serif !important;
    font-weight: 100 !important;
    width: 100% !important;
    font-size: 1.2rem;
  }
    
  .success {background:#00af66;}

  /* Netscore Slider */
  /* .slider {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  
  .slider.slider-horizontal {
    width: 100%;
    height: 20px;
  }
  
  .slider.slider-horizontal .slider-track {
    height: 6px;
    width: 100%;
    margin-top: -3.5px;
    top: 50%;
    left: 0;
  }
  
  .slider.slider-horizontal .slider-selection,
  .slider.slider-horizontal .slider-track-low,
  .slider.slider-horizontal .slider-track-high {
    height: 100%;
    top: 0;
    bottom: 0;
  }
  
  .slider.slider-horizontal .slider-tick,
  .slider.slider-horizontal .slider-handle {
    margin-left: -10px;
  }
  
  .slider.slider-horizontal .slider-tick-container {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .slider.slider-horizontal .slider-tick-label-container {
    white-space: nowrap;
    margin-top: 40px;
  }
  
  .slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    display: inline-block;
    text-align: center;
  }
  
  .slider.slider-horizontal.slider-rtl .slider-track {
    left: initial;
    right: 0;
  }
  
  .slider.slider-horizontal.slider-rtl .slider-tick,
  .slider.slider-horizontal.slider-rtl .slider-handle {
    margin-left: initial;
    margin-right: -10px;
  }
  
  .slider.slider-horizontal.slider-rtl .slider-tick-container {
    left: initial;
    right: 0;
  }
  
  .slider.slider-disabled .slider-handle {
    background-color: #cfcfcf;
    background-image: -moz-linear-gradient(top, #dfdfdf, #bebebe);
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#dfdfdf),
      to(#bebebe)
    );
    background-image: -webkit-linear-gradient(top, #dfdfdf, #bebebe);
    background-image: -o-linear-gradient(top, #dfdfdf, #bebebe);
    background-image: linear-gradient(to bottom, #dfdfdf, #bebebe);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#DFDFDF', endColorstr='#BEBEBE',GradientType=0);
  }
  
  .slider.slider-disabled .slider-track {
    background-color: #e7e7e7;
    background-image: -moz-linear-gradient(top, #e5e5e5, #e9e9e9);
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#e5e5e5),
      to(#e9e9e9)
    );
    background-image: -webkit-linear-gradient(top, #e5e5e5, #e9e9e9);
    background-image: -o-linear-gradient(top, #e5e5e5, #e9e9e9);
    background-image: linear-gradient(to bottom, #e5e5e5, #e9e9e9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E5E5E5', endColorstr='#E9E9E9',GradientType=0);
    cursor: not-allowed;
  }
  
  .slider input {
    display: none;
  }
  
  .slider .hide {
    display: none;
  }
  
  .slider-track {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#f5f5f5),
      to(#f9f9f9)
    );
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F5F5F5', endColorstr='#F9F9F9',GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
  }
  
  .slider-selection {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#f9f9f9),
      to(#f5f5f5)
    );
    background-image: -webkit-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: -o-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: linear-gradient(to bottom, #f9f9f9, #f5f5f5);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F9F9F9', endColorstr='#F5F5F5',GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
  }
  
  .slider-selection.tick-slider-selection {
    border: 2px solid black;
    padding: 2px;
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#52c5ff', endColorstr='#3abcfd',GradientType=0);
  }
  
  .slider-track-low,
  .slider-track-high {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    background: transparent;
  }
  
  .slider-handle {
    background-color: #0361cc;
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0480BE', endColorstr='#036fa5',GradientType=0);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
      0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
      0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
      0 1px 2px rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    border: 0px solid transparent;
  }
  
  .slider-handle:hover {
    cursor: pointer;
  }
  
  .slider-handle.round {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
  
  
  .slider-handle.custom {
    background: transparent none;
  }
  
  .slider-tick {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#f5f5f5),
      to(#f9f9f9)
    );
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F5F5F5', endColorstr='#F9F9F9',GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    filter: none;
    opacity: 0.8;
    border: 0px solid transparent;
  }
  
  .slider-tick.round {
    border-radius: 50%;
  }
  
  .slider-tick.custom {
    background: transparent none;
  }
  
  .slider-tick.in-selection {
    border: 2px solid black;
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#52c5ff', endColorstr='#3abcfd', GradientType=0);
    opacity: 1;
  } */

/* End Marketo CSS */

/* =================================================================================== */
/* New Netscore slider css */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin: 30px;
  width: 500px;
}

/* Solution Marketplace Dividers */

.divider {
  margin-top: 13.125rem;
  margin-bottom: 12.188rem;
}