/* Bootstrap overrides */

.navbar {
    padding: 0rem !important;
}

.dropdown-item {
    padding: .25rem 1.35rem !important;
}

.btn-light,
.dropdown-item:hover,
.dropdown.btn-group:hover {
    background-color: rgb(246, 244, 244) !important;
}

.btn-light.dropdown-toggle:focus {
    box-shadow: none !important;
}

/* END Bootstrap overrides */

/* Nav menu hover state */
.nav-text {
    color: black;
  }
  
  .nav-text-white,
  .dropdown-item:hover {
    color: #014695 !important;
  }

  .nav-item:hover {
    background-color: rgb(246, 244, 244) !important;
  }
  

  @media(min-height: 540px) and (max-height: 639px) {
    .scroll-wrapper {
      height: 29rem;
      overflow-y: scroll;
    }
  }
  
  
  @media(min-height: 640px) and (max-height: 881px) {
    .scroll-wrapper ul {
      margin-bottom: 0rem !important;
      padding-bottom: 0rem !important
    }
  }

  /* Vertical navigation */

.vertical-nav {
  min-width: 17rem;
  width: 17rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in;
  z-index: 1;
}

.sticky-vertical-nav  {
  min-width: 17rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in;
  z-index: 1;
}

.dropdown-menu.show {
  transform: translate(0rem, 2.5rem) !important;
  width: 100%;
}

.dropdown-toggle[aria-expanded="true"]::after,
.dropdown-toggle[aria-expanded="false"]::after  {
  transition: transform 300ms ease-out;
 
}

.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg);
}

.dropdown-toggle[aria-expanded="false"]::after {
  transform: rotate(0deg);
}

.nav-dropdown {
  width: 11.5rem;
}

/* mobile screens landscape */
@media screen and (orientation:landscape)
and (min-device-width: 500px) 
and (max-device-width: 896px) {
  .vertical-nav,
  .sticky-vertical-nav  {
    z-index: 5 !important;
  }

  .fixed-background {
    display: none;
  }
}

/* @media screen and (orientation: landscape) {
  #sidebar.active {
    overflow-y: scroll;
  }
} */

@media (max-width: 1199px) {
  #sidebar {
    transform: translateX(-17rem);
  }
  #sidebar.active {
    transform: translateX(0rem);
  }

  #toggleNavMobile {
    position: fixed;
  }

  #toggleNavMobile {
    transition: all 0.4s ease-in;
    z-index: 1;
  }
  
  .toggleBtn {
    top: 1rem;
    left: 1rem;
  }
  
  .toggleBtn.active {
    left: 17rem;
    top: 1rem;
  }

  .sticky-vertical-nav,
  .vertical-nav  {
    z-index: 5 !important;
    border-right: 1px solid rgba(0,0,0,.2);
  }
  
  .fixed-background {
    display: none;
  }


}

@media (min-width: 1200px) {
  
  .sticky-vertical-nav {
    min-width: 17rem;
    min-height: 44rem;
    position: sticky;
    top: 0;
    left: 0;
    transition: all 0.4s ease-in;
    z-index: 1;
  }

  #toggleNavMobile {
    display: none;
  }
}

  /* Sidenav bar for home page and solutions marketplace */

@media (min-width: 1024px) {
  .svgPage {
    position: absolute !important;
    bottom: 0;
    min-height: 100%;
  }

  .fixed-background {
    min-width: 17rem;
    width: 17rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.4s ease-in;
    z-index: -2;
  } 

  .nav-link {
    font-size: .95rem;
  }

}

.nav-link.active {
  color: white;
  background-color: #004593;
}

/* Padding above donation tracker */

@media(min-width: 1600px) {
  .nav {
    padding-bottom: 4rem !important;
  }

  .nav-link {
    font-size: 1rem;
  }
}

/* Survey Monkey NPS overrides */
.wds-flex--y.wds-flex--x-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  visibility: hidden;
  }

  .sm-simple-logo-header {
  margin: 0 auto;
  max-width: 1440px;
  visibility: hidden;
  }

  .grecaptcha-badge {
  width: 256px;
  height: 60px;
  display: block;
  transition: right 0.3s ease 0s;
  position: fixed;
  bottom: 14px;
  right: -186px;
  box-shadow: grey 0px 0px 5px;
  border-radius: 2px;
  overflow: hidden;
  visibility: hidden;
  }
  
  .wds-container.wds-p-6.wds-flex--x-center.sm-endpage-header.sm-endpage-header--concord.sm-endpage-header--undefined.smEndpageHeader-0-2-2,
  .sm-endpage-header--concord,
  #reactApp .sm-endpage-header--concord {
  background-color: #013C85 !important;
  }