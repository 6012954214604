.customModal {
  border-radius: 0.5rem;
}
.no-padding {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.max-width-27 {
  max-width: 27rem !important;
}

.max-width-30 {
  max-width: 30rem !important;
}
.width-40 {
  width: 40rem;
}
.width-36 {
  width: 36rem;
}

@media (min-width: 992px) {
  .width-47 {
    min-width: 47rem;
  }
}
.arrow-left {
  position: relative;
}
.arrow-left::after {
  content: " ";
  position: absolute;
  width: 0;
  top: 9rem;
  right: 0rem;
  height: 0;
  border-top: 1.3rem solid transparent;
  border-bottom: 1.3rem solid transparent;
  border-left: 1.3rem solid #60ffaa;
}
/* mobile screens landscape */
@media screen and (orientation: landscape) and (min-device-width: 500px) and (max-device-width: 830px) {
  .arrow-left::after {
    border: none;
  }
}

  