.card-container {
  position: relative;
}

.arrow.top .path,
.arrow.bottom-left .path,
.arrow.bottom-right .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s linear forwards;
  animation-iteration-count: 1;
}

.arrow.left-long .path,
.arrow.right-long .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash .5s linear forwards;
  animation-iteration-count: 1;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.arrow.top .arrow-head,
.arrow.bottom-left .arrow-head,
.arrow.bottom-right .arrow-head,
.arrow.left-long .arrow-head {
  animation: arrow 0.4s 0.2s linear forwards;
  opacity: 0;
}

.arrow.left-long .arrow-head,
.arrow.right-long .arrow-head {
  animation: arrow 0.2s 0.4s linear forwards;
  opacity: 0;
}

@keyframes arrow {
  to {
    opacity: 1;
  }
}

.arrow {
  position: absolute;
  display: block;
}

.arrow.top {
  height: 6rem;
  width: 10rem;
  right: -5rem;
  top: -5.6rem;
  transform: rotate(-5deg);
}

.arrow.bottom-left {
  height: 6rem;
  width: 8rem;
  right: 9rem;
  top: 15.2rem;
  transform: rotate(148deg);
}

/* .arrow.bottom-right {
  height: 3rem;
  width: 11rem;
  right: -8rem;
  top: 7.2rem;
  transform: rotate(167deg) scaleX(-1);
} */

.arrow.bottom-right {
  height: 4rem;
  width: 7rem;
  right: 23.2rem;
  top: 4rem;
  transform: rotate(174deg) scaleY(-1);
}

.arrow.left-long {
  height: 5rem;
  width: 38rem;
  right: 23.2rem;
  top: 2.4rem;
  transform: rotate(185deg);
}
/* 
.arrow.right-long{
  height: 3rem;
  width: 11rem;
  right: -28.4rem;
  top: -7.8rem;
  transform: rotate(156deg) scaleX(-1) scale(4);
} */

.arrow.right-long{
  width: 14rem;
  right: -26.8rem;
  top: -4.8rem;
  transform: rotate(150deg) scaleX(-1) scale(2.8);
}

@media (width: 1024px) and (min-height: 640px) and (max-height: 768px) and (orientation:landscape) {
  .arrow.left-long {
    height: 5rem;
    width: 13rem;
    right: 22.8rem;
    top: -3.6rem;
    transform: rotate(204deg);
  }

  .arrow.right-long {
    width: 9rem;
    right: -13.8rem;
    top: 0rem;
    transform: rotate(150deg) scaleX(-1) scale(1.8);
  }
}

@media(min-width: 1200px) and (max-width: 1440px) {
  .arrow.left-long {
    width: 18rem;
  }
}

@media(min-width:1200px) and (max-width: 1400px) {
  .arrow.right-long{
    width: 14rem;
    right: -20.6rem;
    top: -4.8rem;
    transform: rotate(141deg) scaleX(-1) scale(2);
  }
}

@media(min-width: 1401px) and (max-width: 1500px) {
  .arrow.right-long{
    width: 14rem;
    right: -23.6rem;
    top: -4.8rem;
    transform: rotate(141deg) scaleX(-1) scale(2.5);
  }
}

@media(min-width: 1501px) and (max-width: 1660px) {
  .arrow.right-long{
    width: 14rem;
    right: -26rem;
    top: -4.8rem;
    transform: rotate(145deg) scaleX(-1) scale(2.8);
  }
}

@media(min-width: 1441px) and (max-width: 1800px) {
  .arrow.left-long {
    width: 28rem;
  }
}

@media(min-width: 2560px) {
  .arrow.left-long {
    width: 66rem;
    height: 10rem;
  }
}

@media(min-width: 2560px) {
  .arrow.right-long{
    transform: rotate(155deg) scaleX(-1) scale(4);
    right: -35.3rem;
    top: -9rem;
  }
}

/* React Tour */

.reactour__helper {
  opacity: 0;
}

.reactour__helper--is-open {
  -webkit-animation: 1s ease 0s normal forwards 1 fadein;
  animation: 1s ease 0s normal forwards 1 fadein;
  max-width: 25rem !important;
}

.reactour__helper--is-open div button:first-child {
  display: none;
  visibility: hidden;
  cursor: none;
  z-index: -100;
  position: absolute;
}

.reactour__helper--is-open div button:nth-child(2) {
  width: 0px;
  margin-left: 50%;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.step-1:after,
.step-2:after,
.step-3:after,
.step-4:after {
  position: absolute;
  display: block;
  color: #4B4B4B;
  font-weight: 600;
  font-size: .8rem;
}

.step-1:after{
  content: 'Step 1 of 5';
  top: 125%;
}

.step-2:after {
  content: 'Step 2 of 5';
  top: 139%;
}

.step-3:after {
  content: 'Step 3 of 5';
  top: 140%;
}

.step-4:after {
  content: 'Step 4 of 5';
  top: 120%;
}
