/* #marketplaceBike {
    animation: marketplace-bike 34s 12s ease-out infinite forwards;
  }
  #signBike {
    animation: sign-bike 40s 40s ease-out infinite forwards;
  }
  #hospitalBike {
    animation: hospital-bike 60s 25s ease-out infinite forwards;
  }
  #leftRoadBike {
    opacity: 0;
    animation: close-road-bike 100s 50s ease-out infinite forwards;
  }
  #rightTopRoadBike {
    animation: top-road-bike 80s ease-out infinite forwards;
  } */
  
  circle[data-name="leftRoadBike"],
  circle[data-name="hospitalBike"],
  circle[data-name="theaterBike"],
  circle[data-name="rightTopRoadBike"],
  circle[data-name="signBike"],
  circle[data-name="marketplaceBike"] {
    fill: rgb(242, 105, 37);
    opacity: 0;
  }

  /* #theaterBike {
    animation: theater-bike 40s ease-out infinite forwards;
  } */
  #marketplaceBike:hover,#signBike:hover,#hospitalBike:hover,#leftRoadBike:hover,#rightTopRoadBike:hover,#theaterBike:hover {
    position: absolute;
    cursor: pointer;
    animation-play-state: paused;
  }
  /* @keyframes marketplace-bike {
    80%, 100% {
    transform: translateX(-29rem) translateY(-15.6rem) translate3d(0, 0, 0);
  }
  } */
  /* @keyframes sign-bike {
    75%, 100% {
    transform: translateX(55rem) translateY(-32rem) translate3d(0, 0, 0);
  }
  } */
  /* @keyframes hospital-bike {
    50%, 100% {
    transform: translateX(-80rem) translateY(45rem) translate3d(0, 0, 0);
  }
  } */
  /* @keyframes close-road-bike {
    0% {
      opacity: 1;
    transform: translatex(-21rem) translateY(-14rem) translate3d(0, 0, 0);
    }
    80%, 100% {
      opacity: 1;
    transform: translateX(108rem) translateY(61rem) translate3d(0, 0, 0);
  }
  } */
  /* @keyframes top-road-bike {
    40%, 100% {
    transform: translateX(-110rem) translateY(-63rem) translate3d(0, 0, 0);
  }
  } */
  /* @keyframes theater-bike {
    0% {
    transform: translatex(-98rem) translateY(20rem) translate3d(0, 0, 0);
  }
  80%, 100% {
    transform: translatex(-41rem) translateY(-13rem) translate3d(0, 0, 0);
  }
  } */

  #bubbles path.cls-122 {
    fill: white;
    filter: drop-shadow(-5px 10px 10px rgba(0, 0, 0, 0.7));
  }

  .citySVG {
    /* width: 100%; */
    transform: scale(1.3) translateY(-16%) translateX(7%) translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .city-wrapper {
    min-height: 53rem;
  }
  
  
  /* mobile screens landscape */
  @media screen and (orientation: landscape) and (min-device-width: 569px) and (max-device-width: 1200px) {
    .citySVG {
    transform: scale(1.8) translateY(-1rem) translateX(1rem) translateZ(0);
    background: #56a145;
    -webkit-backface-visibility: hidden;
  }
  
  .city-wrapper {
    min-height: 30rem !important;
  }
  
  #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
    display: none;
    visibility: hidden;
  }
  }
  
  /* @media only screen and (min-width: 790px) and (orientation: landscape)  { 
    .citySVG {
      transform: scale(1.4) translateY(6.5rem) translateX(-3rem);
      background-color: #56a145;
    }
  
    .city-wrapper {
      min-height: 38rem !important;
    } */
  
    /* #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
      display: none;
      visibility: hidden;
    } */
  /* } */

  

  
  @media(min-width: 800px) and (max-width: 1023px) {
    .citySVG {
      transform: scale(1.5) translateY(-5rem) translateX(-7rem) translateZ(0);
      -webkit-backface-visibility: hidden;
      background: #56a145;
    }
    .city-wrapper {
      min-height: 38rem !important;
    }
  }


    
  @media(min-width: 800px) and (max-width: 1023px) and (orientation: landscape) {
    .citySVG {
      transform: scale(1.3) translateY(-9rem) translateX(-4.5rem) translateZ(0);
      -webkit-backface-visibility: hidden;
      background: #56a145;
    }
    
  }


  /* iPad Air */
@media only screen and (device-width: 820px) and (device-height: 1180px) and (orientation : landscape) { 
  .citySVG {
    transform: scale(1.8) translateY(-3rem) translateX(2rem) translateZ(0) !important;
    -webkit-backface-visibility: hidden;
    background: #56a145;
  }
}



  @media(min-width: 1024px) and (max-width: 1199px) {
    .citySVG {
      transform: scale(2.2) translateY(1rem) translateX(2rem) translateZ(0);
      -webkit-backface-visibility: hidden;
      background: #56a145;
    }
  }

  @media only screen and (min-width: 820px) and (max-width: 900px) and (orientation: landscape) {
    .citySVG {
      transform: scale(1.3) translateY(-10.5rem) translateX(-5rem) translateZ(0);
      -webkit-backface-visibility: hidden;
      background: #56a145;
    }

    .city-wrapper {
      min-height: 31rem !important;
    }

    #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike{
      display: none;
      visibility: hidden;
    }

    .donation-tracker-wrapper {
      display: none;
    }
  }
  
  @media(min-width: 1200px) and (max-width: 1800px) {
    .citySVG {
      transform: scale(1.77) translateY(-2%) translateX(7rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
  }

  /* @media(min-width: 1801px) and (max-width: 2000px) {
    .citySVG {
      transform: scale(1.2) translateY(0rem) translateX(7rem);
    }
  } */
  /* @media (min-width: 768px) and (max-width: 1024px) {
      .citySVG {
      transform: scale(1.3) translateY(11%) translateX(-5%) translateZ(0);
    }
  } */
  
  /* @media (width: 1024px) and (height: 640px) {
    .citySVG {
    transform: scale(1.3) translateY(7%) translateX(-5%) translateZ(0);
  }
  } */
  
  /* ============================================================================== */
  
  /* 1024 x 768 resolution */
  @media (width: 1024px) and (min-height: 640px) and (max-height: 768px) and (orientation:landscape) {
      .citySVG {
      transform: scale(1.34) translateY(-11.5rem) translateX(-7rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
    #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
      display: block;
      visibility: visible;
    }
  }
  
  /* MOZILLA 1024 x 768 resolution */
  @media all and (min--moz-device-pixel-ratio:0) and (width: 1024px) and (min-height: 640px) and (max-height: 768px) and (orientation:landscape){
    .citySVG {
      transform: scale(1.2) translateY(-11.5rem) translateX(-4rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
    #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
      display: block;
      visibility: visible;
    }
  }
  /* ============================================================================== */
  
  
  /* ============================================================================== */
  /* 1280 x 800 resolution RELOOK */
  @media screen and (width: 1280px) and (min-height: 680px) and (max-height: 800px) and (orientation:landscape) {
      .citySVG {
      transform: scale(1.2) translateY(-13rem) translateX(-4rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
    #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
      display: block;
      visibility: visible;
    }
  }
  
  /* MOZILLA 1280 x 800 resolution  RELOOK */
  @media all and (min--moz-device-pixel-ratio:0) and (width: 1280px) and (min-height: 500px) and (max-height: 1000px) and (orientation:landscape){
    .citySVG {
      transform: scale(1.7) translateY(0rem) translateX(8rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
    #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
      display: block;
      visibility: visible;
    }
  }
  /* ============================================================================== */
  
  
  /* ============================================================================== */
  /* 1280 x 1024 resolution */
  @media screen and (width: 1280px) and (min-height: 850px) and (max-height: 1024px) and (orientation:landscape) {
    .citySVG {
    transform: scale(1.221) translateY(-14rem) translateX(-5.8rem) translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
    display: block;
    visibility: visible;
  }
  }
  /* ============================================================================== */
  
  /*  1440 x 900 resolution  */
  @media screen and (width: 1440px) and (min-height: 680px) and (max-height: 910px) and (orientation:landscape){
    .citySVG {
      transform: scale(1.7) translateY(-3rem) translateX(7rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
  }
  
  /* MOZILLA 1440 x 900 resolution   */
  @media all and (min--moz-device-pixel-ratio:0) and (width: 1440px) and (min-height: 680px) and (max-height: 910px) and (orientation:landscape){
    .citySVG {
      transform: scale(1.6) translateY(-3rem) translateX(8rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
  }
  
  /* ============================================================================== */
  /* 1680 x 1050 resolution */
  @media screen and (min-width: 1550px) and (max-width: 1850px) and (min-height: 600px) and (max-height: 1000px) and (orientation:landscape) {
    .citySVG {
      transform: scale(1.6) translateY(-6rem) translateX(8rem) translateZ(0);
      -webkit-backface-visibility: hidden;
  }
  }
  /* ============================================================================== */
  
  /* ============================================================================== */
  /* 1600 x 1200 resolution */
  @media screen and (width: 1600px) and (min-height: 900px) and (max-height: 1200px) and (orientation:landscape) {
    .citySVG {
    transform: scale(1.65) translateY(-4rem) translateX(8rem) translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  }
  /* ============================================================================== */
  
  /* ============================================================================== */
  /* 1680 x 1050 resolution */
  @media screen and (width: 1680px) and (min-height: 850px) and (max-height: 1050px) and (orientation:landscape) {
      .citySVG {
        transform: scale(1.5) translateY(-7rem) translateX(8rem) translateZ(0);
        -webkit-backface-visibility: hidden;
    }
  }
  /* ============================================================================== */
  
  
  /* ============================================================================== */
  /* 1920 x 1200 resolution */
  @media screen and (width: 1920px) and (min-height: 1000px) and (max-height: 1200px) and (orientation:landscape) {
    .citySVG {
      transform: scale(1.65) translateY(-6rem) translateX(7rem) translateZ(0);
      -webkit-backface-visibility: hidden;
  }
  }
  
  /* MOZILLA 1280 x 800 resolution  */
  /* @media all and (min--moz-device-pixel-ratio:0) and (width: 1920px) and (min-height: 700px) and (max-height: 1200px) and (orientation:landscape){
    .citySVG {
      transform: scale(2) translateY(10%) translateX(-5%) translateZ(0);
    }
  } */
  
  /* ============================================================================== */
  /* 1920 x 1080 resolution */
  
  @media screen and (width: 1920px) and (min-height: 1000px) and (max-height: 1080px) and (orientation:landscape) {
    .citySVG {
      transform: scale(1.4) translateY(-11rem) translateX(8rem) translateZ(0);
      -webkit-backface-visibility: hidden;
  }
  }
  
  /* ============================================================================== */
  
  
  
  /* 1920 x 955 resolution */
  @media screen and (width: 1920px) and (height: 955px) and (orientation:landscape) {
    .citySVG {
      transform: scale(1.25) translateY(-12rem) translateX(8rem) translateZ(0);
      -webkit-backface-visibility: hidden;
  }
  }


  /* ============================================================================== */
  /* 2048 x 1540 resolution  MOZILLA*/
  @media all and (min--moz-device-pixel-ratio:0) and (width: 2048px) and (min-height: 1300px) and (max-height: 1540px) and (orientation:landscape) {
    .citySVG {
      transform: scale(1.3) translateY(-4rem) translateX(6rem) translateZ(0);
      -webkit-backface-visibility: hidden;
  }
  }
  
  /* 2048 x 1540 resolution */
  @media screen and (width: 2048px) and (min-height: 1300px) and (max-height: 1540px) and (orientation:landscape) {
    .citySVG {
      transform: scale(1.9) translateY(-3rem) translateX(9rem) translateZ(0);
      -webkit-backface-visibility: hidden;
  }
  }
  
  /* ============================================================================== */



  
  /* mobile screens landscape */
  @media screen and (orientation: landscape) and (min-device-width: 500px) and (max-device-width: 568px) {
    .citySVG {
    transform: scale(1.35) translateY(5rem) translateX(-2rem) translateZ(0);
    background-color: #56a145;
    -webkit-backface-visibility: hidden;
  }
  .city-wrapper {
    min-height: 23rem !important;
  }
  
  #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
    display: none;
    visibility: hidden;
  }
  }
  /* This media query is used for: iPhone 11 Pro Max and iPhone Xs Max */
  @media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3)
    and (orientation:landscape) {
      .citySVG {
        transform: scale(30) translateY(-3rem) translateX(9rem) translateZ(0);
        -webkit-backface-visibility: hidden;
     }
     .city-wrapper {
      min-height: 40rem !important;
    }
     }
  


  
  /* @media screen and (orientation: landscape) and (min-device-width: 790px) and (max-device-width: 830px) {
    .city-wrapper {
      min-height: 36rem !important;
    } */
  /* } */
  
  
  
  /* iPhone landscape, iPhone Xs Max/11 Pro Max */
  /* @media only screen 
      and (min-device-width : 414px) 
      and (max-device-height : 896px) 
      and (-webkit-device-pixel-ratio : 3)
      and (orientation : landscape) { 
      .citySVG {
        transform: scale(4) translateY(5rem) translateX(-2rem);
        background-color: #56a145;
      }
      .city-wrapper {
        min-height: 5rem !important;
      }
      
      #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
        display: none;
        visibility: hidden;
      }
  } */

  
  @media only screen and (min-width: 300px) and (max-width: 700px) and (orientation: landscape) and (-webkit-device-pixel-ratio : 2) {
    .citySVG {
      transform: scale(1.4) translateY(-1rem) translateX(0rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
    .city-wrapper {
      min-height: 30rem !important;
    }
  
    #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
      display: none;
      visibility: hidden;
    }
  }

    /* ----------- iPhone X  Landscape----------- */
    @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 
  .citySVG {
    transform: scale(1.55) translateY(-3.8rem) translateX(3rem) translateZ(0) !important;
    background-color: #56a145;
    -webkit-backface-visibility: hidden;
  }
  .city-wrapper {
    min-height: 23rem !important;
  }
  
  #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
    display: none;
    visibility: hidden;
  }
}

  /* iPhone landscape, iPhone Xs Max/11 Pro Max */
  @media only screen and (min-width: 414px) and (max-width: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio : 3) {
    .citySVG {
      transform: scale(1.5) translateY(-6.5rem) translateX(-7rem) translateZ(0);
      -webkit-backface-visibility: hidden;
    }
    .city-wrapper {
      min-height: 38rem;
    }
  
    #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
      display: none;
      visibility: hidden;
    }
  }



  /*  iPhone 13, iPhone 12 and iPhone 12 Pro */
  @media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) { 
      .citySVG {
        transform: scale(2.2) translateY(1rem) translateX(2rem) translateZ(0);
        -webkit-backface-visibility: hidden;
      }
      #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
        display: none;
        visibility: hidden;
      }
    }

    /* iPhone 11 and iPhone XR */
    @media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) {
      .citySVG {
        transform: scale(1.65) translateY(-2rem) translateX(-7rem) translateZ(0);
        -webkit-backface-visibility: hidden;
      }
      #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
        display: none;
        visibility: hidden;
      }
      .city-wrapper {
        min-height: 45rem !important;
      }
     }

     @media only screen and (width: 1080px) and (orientation: landscape) { 

      .citySVG {
        transform: scale(1.7) translateY(-1.5rem) translateX(2rem) translateZ(0);
        -webkit-backface-visibility: hidden;
      }
     }

  /* iPad in landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
  /* iPad in Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .citySVG {
    transform: scale(1.7) translateY(0rem) translateX(-9rem) translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  }
  @media screen and (orientation: landscape) {
    /* .city-wrapper {
    min-height: 45rem;
  } */
  }
  @media (max-width: 767px) {
    .city-wrapper {
    min-height: 50rem;
  }
  #marketplaceBike, #signBike, #hospitalBike, #closeRoadBike, #rightTopRoadBike, #theaterBike, #leftRoadBike {
    display: none;
    visibility: hidden;
  }
}






.citySVG .cls-1,
.citySVG .cls-15,
.citySVG .cls-18,
.citySVG .cls-239,
.citySVG .cls-241,
.citySVG .cls-243,
.citySVG .cls-274,
.citySVG .cls-315,
.citySVG .cls-501,
.citySVG .cls-502,
.citySVG .cls-6 {
	fill: none
}

.citySVG .cls-2 {
	isolation: isolate
}

.citySVG .cls-3 {
	fill: #56a145
}

.citySVG .cls-4 {
	fill: #5f5850
}

.citySVG .cls-5 {
	fill: #6d655c
}

.citySVG .cls-15,
.citySVG .cls-18,
.citySVG .cls-6 {
	stroke: #b2b3b6
}

.citySVG .cls-15,
.citySVG .cls-18,
.citySVG .cls-239,
.citySVG .cls-241,
.citySVG .cls-243,
.citySVG .cls-274,
.citySVG .cls-6 {
	stroke-linecap: round
}

.citySVG .cls-15,
.citySVG .cls-501,
.citySVG .cls-502,
.citySVG .cls-6 {
	stroke-miterlimit: 10
}

.citySVG .cls-15,
.citySVG .cls-6 {
	stroke-width: 3.88px
}

.citySVG .cls-15 {
	stroke-dasharray: 15.5
}

.citySVG .cls-17 {
	fill: #929496
}

.citySVG .cls-18,
.citySVG .cls-239,
.citySVG .cls-241,
.citySVG .cls-243,
.citySVG .cls-274,
.citySVG .cls-315 {
	stroke-linejoin: round
}

.citySVG .cls-18,
.citySVG .cls-239,
.citySVG .cls-241,
.citySVG .cls-243,
.citySVG .cls-274,
.citySVG .cls-501,
.citySVG .cls-502 {
	stroke-width: 1.29px
}

.citySVG .cls-19 {
	fill: #cccccb
}

.citySVG .cls-20,
.citySVG .cls-24,
.citySVG .cls-57 {
	fill: #5d574f
}

.citySVG .cls-20,
.citySVG .cls-23,
.citySVG .cls-24,
.citySVG .cls-244,
.citySVG .cls-276,
.citySVG .cls-520,
.citySVG .cls-54,
.citySVG .cls-56 {
	mix-blend-mode: multiply
}

.citySVG .cls-20,
.citySVG .cls-23,
.citySVG .cls-276 {
	opacity: .45
}

.citySVG .cls-21 {
	fill: #1e6a00
}

.citySVG .cls-22 {
	fill: #388f24
}

.citySVG .cls-23,
.citySVG .cls-319 {
	fill: #27720a
}

.citySVG .cls-24 {
	opacity: .3
}

.citySVG .cls-25 {
	fill: #1e5900
}

.citySVG .cls-26,
.citySVG .cls-54 {
	fill: #231f20
}

.citySVG .cls-27 {
	fill: #598fa6
}

.citySVG .cls-28 {
	fill: #5c595a
}

.citySVG .cls-29 {
	fill: #b2b3b6
}

.citySVG .cls-30 {
	fill: #ae8658
}

.citySVG .cls-31 {
	fill: #6a3e17
}

.citySVG .cls-32 {
	fill: #f1e4cd
}

.citySVG .cls-33 {
	fill: #cbae8a
}

.citySVG .cls-34 {
	fill: #3f6c82
}

.citySVG .cls-35 {
	fill: #9b6930
}

.citySVG .cls-36 {
	fill: #a77b49
}

.citySVG .cls-37 {
	fill: #5797af
}

.citySVG .cls-38 {
	fill: #75c0d5
}

.citySVG .cls-39 {
	fill: #2d667d
}

.citySVG .cls-40 {
	fill: #47abc7
}

.citySVG .cls-41 {
	fill: #9ad1e0
}

.citySVG .cls-42 {
	fill: #367bb1
}

.citySVG .cls-43 {
	fill: #84bfd3
}

.citySVG .cls-244,
.citySVG .cls-44 {
	fill: #004e74
}

.citySVG .cls-276,
.citySVG .cls-45,
.citySVG .cls-56 {
	fill: #754c28
}

.citySVG .cls-46 {
	fill: #aac53a
}

.citySVG .cls-47 {
	fill: #aa1b1c
}

.citySVG .cls-48 {
	fill: #d39c3a
}

.citySVG .cls-49 {
	fill: #9e6235
}

.citySVG .cls-50 {
	fill: #fed437
}

.citySVG .cls-51 {
	fill: #ffefb5
}

.citySVG .cls-52 {
	fill: #443d34
}

.citySVG .cls-54,
.citySVG .cls-56 {
	opacity: .25
}

.citySVG .cls-55 {
	fill: #bb6528
}

.citySVG .cls-58 {
	fill: #e8e9eb
}

.citySVG .cls-59 {
	fill: #991b1c
}

.citySVG .cls-60 {
	fill: #f16a24
}

.citySVG .cls-61 {
	fill: #ff8a34
}

.citySVG .cls-62 {
	fill: #111
}

.citySVG .cls-64 {
	fill: #060606
}

.citySVG .cls-65 {
	fill: #2d2d2d
}

.citySVG .cls-66 {
	fill: #26272b
}

.citySVG .cls-67 {
	fill: #3f3f3f
}

.citySVG .cls-68 {
	fill: #bfbcc4
}

.citySVG .cls-69 {
	fill: #aaa
}

.citySVG .cls-70 {
	fill: #646464
}

.citySVG .cls-71 {
	fill: #1b1414
}

.citySVG .cls-72 {
	fill: #514f50
}

.citySVG .cls-73 {
	fill: #838082
}

.citySVG .cls-74 {
	fill: #0e0530
}

.citySVG .cls-76 {
	fill: #2a5b0a
}

.citySVG .cls-77 {
	fill: #08041e
}

.citySVG .cls-78 {
	fill: #eaedef
}

.citySVG .cls-79 {
	fill: #e7e4ec
}

.citySVG .cls-80 {
	fill: #9a96a3
}

.citySVG .cls-81 {
	fill: #4c4c4c
}

.citySVG .cls-84 {
	fill: #05031c
}

.citySVG .cls-86 {
	fill: #cecece
}

.citySVG .cls-87 {
	fill: #919191
}

.citySVG .cls-88 {
	fill: #252525
}

.citySVG .cls-89 {
	fill: #0f0f0f
}

.citySVG .cls-96 {
	fill: #969696
}

.citySVG .cls-103 {
	fill: #fdbd9c
}

.citySVG .cls-108 {
	fill: #ed937e
}

.citySVG .cls-109 {
	fill: #e5e5e5
}

.citySVG .cls-110 {
	fill: #91a0aa
}

.citySVG .cls-111 {
	fill: #81929b
}

.citySVG .cls-112 {
	fill: #4a5a66
}

.citySVG .cls-113 {
	fill: #6c7e8c
}

.citySVG .cls-114 {
	fill: #3f535b
}

.citySVG .cls-115 {
	fill: #586a75
}

.citySVG .cls-118 {
	fill: #f9aa8c
}

.citySVG .cls-119 {
	fill: #a6a6a7
}

.citySVG .cls-120 {
	fill: #828282
}

.citySVG .cls-122 {
	fill: #fff
}

.citySVG .cls-143 {
	fill: #1c1c1c
}

.citySVG .cls-144 {
	fill: #ea0000
}

.citySVG .cls-145 {
	fill: #4b5d66
}

.citySVG .cls-147 {
	fill: #e09a7e
}

.citySVG .cls-148 {
	fill: #cc866e
}

.citySVG .cls-149 {
	fill: #100a4f
}

.citySVG .cls-150 {
	fill: #2baaa3
}

.citySVG .cls-152 {
	fill: #120a44
}

.citySVG .cls-153 {
	fill: #1c827a
}

.citySVG .cls-154 {
	fill: #212121
}

.citySVG .cls-155 {
	fill: #51666d
}

.citySVG .cls-157 {
	fill: #326610
}

.citySVG .cls-169 {
	fill: #f90629
}

.citySVG .cls-170 {
	fill: #edc562
}

.citySVG .cls-171 {
	fill: #6e0000
}

.citySVG .cls-172 {
	fill: #070635
}

.citySVG .cls-173 {
	fill: #c48069
}

.citySVG .cls-174 {
	fill: #ff8b1d
}

.citySVG .cls-175 {
	fill: #ff8114
}

.citySVG .cls-176 {
	fill: #471f12
}

.citySVG .cls-177 {
	fill: #7f7f7f
}

.citySVG .cls-179 {
	fill: #bcbcbc
}

.citySVG .cls-181 {
	fill: #a3a3a3
}

.citySVG .cls-194 {
	fill: #141266
}

.citySVG .cls-195 {
	fill: #ffb86f
}

.citySVG .cls-196 {
	fill: #223c78
}

.citySVG .cls-232 {
	fill: #83a83d
}

.citySVG .cls-235 {
	fill: #00589c
}

.citySVG .cls-239 {
	stroke: #2d667d
}

.citySVG .cls-241 {
	stroke: #004e74
}

.citySVG .cls-243 {
	stroke: #00589c
}

.citySVG .cls-244,
.citySVG .cls-520 {
	opacity: .4
}

.citySVG .cls-248 {
	fill: #90c7d8
}

.citySVG .cls-249 {
	fill: #4b8198
}

.citySVG .cls-272 {
	fill: #f7f0e3
}

.citySVG .cls-273 {
	fill: #5391a9
}

.citySVG .cls-274,
.citySVG .cls-315 {
	stroke: #eb008b
}

.citySVG .cls-277,
.citySVG .cls-278 {
	fill: #a1713b;
	fill-rule: evenodd
}

.citySVG .cls-278 {
	fill: #d3ba9c
}

.citySVG .cls-306 {
	fill: #1cc1c7
}

.citySVG .cls-307 {
	fill: #6649b2
}

.citySVG .cls-308 {
	fill: #4826a3
}

.citySVG .cls-309 {
	fill: #fbd50b
}

.citySVG .cls-310 {
	fill: #b5b700
}

.citySVG .cls-311 {
	fill: #b74820
}

.citySVG .cls-312 {
	fill: #004060
}

.citySVG .cls-313 {
	fill: #1f6ca8
}

.citySVG .cls-314 {
	fill: #dbc6ad
}

.citySVG .cls-315 {
	stroke-width: .5px
}

.citySVG .cls-316 {
	fill: #d45100
}

.citySVG .cls-317 {
	fill: #ff9c54
}

.citySVG .cls-318 {
	fill: #83af15
}

.citySVG .cls-463 {
	fill: #1d4c70
}

.citySVG .cls-480 {
	fill: #f68622
}

.citySVG .cls-481 {
	fill: #e2202a
}

.citySVG .cls-493 {
	fill: #295f81
}

.citySVG .cls-494 {
	fill: #9e9fa3
}

.citySVG .cls-501 {
	stroke: #cccccb
}

.citySVG .cls-502 {
	stroke: #929496
}

.citySVG .cls-518 {
	fill: #fcfcf5;
	opacity: .86
}

.citySVG .cls-519 {
	fill: red
}

.citySVG .cls-521 {
  font-size: 1.8rem;
  fill: #014695;
  font-family: FSAlbertArabicRegular, Arial, Helvetica, sans-serif;
  text-rendering: geometricprecision;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  font-weight: 700;
}

.citySVG .cls-522 {
  font-size: 1.25rem;
  font-family: FSAlbertArabicRegular, Arial, Helvetica, sans-serif;
  text-rendering: geometricprecision;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  font-weight: 500;
}

