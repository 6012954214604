
.mobileDivider-hpc {
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.mobileDivider-hpc .cls-4 {
	fill: #b3b3b6
}

.mobileDivider-hpc .cls-6 {
	fill: none;
	stroke: #a6cede;
	stroke-linecap: round;
	stroke-miterlimit: 10;
	stroke-width: 2.48px
}

.mobileDivider-hpc .cls-9 {
	fill: #6d655c
}

.mobileDivider-hpc .cls-12 {
	fill: #f68622
}

.mobileDivider-hpc .cls-13 {
	fill: #aac538
}

