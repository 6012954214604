.pop-up {
  position: fixed;
  bottom: -100%;
  left: 70%;
  min-width: 77rem;
  z-index: 2;
  transition: bottom 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  visibility: hidden;
  opacity: 0;
}
.pop-up.show {
  bottom: 15%;
  visibility: visible;
  opacity: 1;
}
.pop-up .sign {
  position: relative;
  border: .35rem solid #1e3641;
  width: 90%;

}
.pop-up .sign::after {
  content: ' ';
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 104%;
  width: 1.75rem;
  height: 14rem;
  background-color: #fff;
  background-image: linear-gradient(to right, #59646a, #b4bec3, #59646a);
}
.pop-up .sign::before {
  content: ' ';
  display: block;
  position: absolute;
  left: -.20rem;
  right: 0;
  bottom: -1.3rem;
  transform: perspective(300px) rotateX(-30deg);
  border: .35rem solid #1e3641;
  background-color: #fff;
  width: 102%;
  height: 1.2rem;
  border-width: .6rem .4rem .35rem .4rem;
}
@media(max-width: 767px) {
  .pop-up {
  display: none;
  visibility: hidden;
}
}
/* mobile screens landscape */
@media screen and (orientation: landscape) and (min-device-width: 500px) and (max-device-width: 1200px) {
  .pop-up.show {
  display: none;
  visibility: hidden;
}
}


@media(max-width: 768px) {
  .pop-up {
  bottom: -20rem;
  left: 50%;
  min-width: 73rem;
  z-index: 2;
  right: 50%;
  transform: translateX(-11rem);
}
}
@media(min-width: 769px) and (max-width: 1199px) {
  .pop-up {
  left: 58%;
}
}
@media(min-width: 1200px) and (max-width: 1400px) {
  .pop-up {
  left: 62%;
}
}
@media(min-width: 1401px) and (max-width: 1700px) {
  .pop-up {
  left: 66%;
}
}