.solutions-container-fluid {
	min-height: 88vh;
	background-color: black;
}


@media(min-width: 1800px) {
	.solutions-container-fluid{
		height: 90vh;
	}

	
}

@media(min-width: 1008px) {
	#solutionsMarket {
		transform: scale(1.1) translate(10rem, 1rem);
	}
}


#solutionsMarket .bubble.cls-153 {
	filter: drop-shadow(-5px 10px 10px rgba(0, 0, 0, 0.7));
}

@media screen and (orientation: landscape) and (min-device-width: 569px) and (max-device-width: 1200px){
	#solutionsMarket {
		transform: scale(1.6) translateY(5rem) translateX(2.5rem);
	}
}

@media screen and (orientation: portrait) and (min-device-width: 569px) and (max-device-width: 1200px)  {
	#solutionsMarket {
		transform: scale(1.6) translateY(5rem) translateX(2.5rem);
	}
}

/* mobile screens landscape */
@media screen and (orientation: landscape) and (min-device-width: 500px) and (max-device-width: 830px) {
	#solutionsMarket {
	transform: scale(1.4) translateY(1.9rem) translateX(-1rem);
}
}
/* tablet screens landscape */
@media screen and (orientation: landscape) and (min-device-width: 700px) and (max-device-width: 882px) {
	#solutionsMarket {
	transform: scale(1.4) translateY(2rem) translateX(3rem);
}
}
/* small laptops */
@media (min-width: 1024px) and (max-width: 1199px) {
	#solutionsMarket {
	transform: scale(1.6) translateY(5rem) translateX(4rem);
}
}

@media screen and (orientation: landscape){
.market-wrapper {
    min-height: 39rem;
}
}


/* ============================================================================== */
/* 1280 x 800 resolution  */
@media screen and (width: 1280px) and (min-height: 580px) and (max-height: 800px) and (orientation:landscape) {
    #solutionsMarket {
    transform: scale(1.1) translateY(0rem) translateX(10rem) translateZ(0);
  }
}

/* MOZILLA 1280 x 800 resolution  */
@media all and (min--moz-device-pixel-ratio:0) and (width: 1280px) and (min-height: 600px) and (max-height: 810px) {
	#solutionsMarket {
	  transform: scale(1.1) translateY(0) translateX(10rem) translateZ(0);
	}
  }

/* ============================================================================== */

/* ============================================================================== */
/* 1280 x 1024 resolution */
@media screen and (width: 1280px) and (min-height: 850px) and (max-height: 1024px) and (orientation:landscape) {
	#solutionsMarket {
	transform: scale(1.3) translateY(4rem) translateX(8rem) translateZ(0);
  	}
  }
  /* ============================================================================== */

/* ============================================================================== */
/* 1600 x 1200 resolution */

@media all and (min--moz-device-pixel-ratio:0) and (min-width: 1500px) and (max-width: 1700px) {
	#solutionsMarket {
	  transform: scale(1) translateY(-7%) translateX(7%) translateZ(0);
	}
  }

@media screen and (width: 1600px) and (min-height: 700px) and (max-height: 1200px) and (orientation:landscape) {
	#solutionsMarket {
	transform: scale(1.2) translateY(0rem) translateX(9rem) translateZ(0);
  }
}
  /* ============================================================================== */

/* 1920 x 1200 resolution */
  @media all and (min--moz-device-pixel-ratio:0) and (min-width: 1900px) and (max-width: 1920px) and (min-height: 1000px) and (max-height: 1200px) and (orientation:landscape) {
	#solutionsMarket {
	transform: scale(1.1) translateY(0) translateX(10rem) translateZ(0);
  }
}

@media screen and (min-width: 1900px) and (max-width: 1920px) and (min-height: 1000px) and (max-height: 1200px) and (orientation:landscape) {
	#solutionsMarket {
	transform: scale(1.1) translateY(0) translateX(10rem) translateZ(0);
  }
}
  /* ============================================================================== */

/* ============================================================================== */

/* 2048 x 1540 resolution */
@media screen and (width: 2048px) and (min-height: 1300px) and (max-height: 1540px) and (orientation:landscape) {
	#solutionsMarket {
	  transform: scale(1.1) translateY(0rem) translateX(10rem) translateZ(0);
  }
  }
  
  /* ============================================================================== */

/* mobile screens landscape */
@media screen and (orientation: landscape) and (min-device-width: 300px) and (max-device-width: 830px) {
    #solutionsMarket {
    transform: scale(1.7) translateY(5rem) translateX(2rem);
    background-color: #56a145;
  }

  .market-wrapper {
    min-height: 29rem !important;
  }

}

@media screen and (orientation: landscape) and (min-device-width: 790px) and (max-device-width: 830px) {
	.market-wrapper {
    min-height: 36rem !important;
  }
}
  /* ============================================================================== */

/* iPhone landscape, iPhone Xs Max/11 Pro Max */
@media only screen and (min-width: 414px) and (max-width: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio : 3) {
	#solutionsMarket {
	  transform: scale(1.5) translateY(3rem) translateX(2rem);
	  background-color: #56a145;
	}
	.market-wrapper {
	  min-height: 30rem !important;
	}
  
  }
  
  /* iPhone landscape, iPhone6,7,8 */
  @media only screen and (min-width: 300px) and (max-width: 700px) and (orientation: landscape) and (-webkit-device-pixel-ratio : 2) {
	#solutionsMarket {
	  transform: scale(1.8) translateY(4rem) translateX(1.5rem);
	  background-color: #56a145;
	}
	.market-wrapper {
	  min-height: 30rem !important;
	}
  }
  

/*ipad PRo 12.9"*/
/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https: //medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
	#solutionsMarket {
	transform: scale(2.5) translateX(1rem) translateY(10rem);
}
}
/* medium laptops */
/* @media (min-width: 1200px) and (max-width: 1439px) {
	#solutionsMarket {
	transform: scale(1.5) translateY(5rem) translateX(5rem);
}
} */




#solutionsMarket .cls-1,
#solutionsMarket .cls-10,
#solutionsMarket .cls-105,
#solutionsMarket .cls-106,
#solutionsMarket .cls-164,
#solutionsMarket .cls-233,
#solutionsMarket .cls-234,
#solutionsMarket .cls-45,
#solutionsMarket .cls-46,
#solutionsMarket .cls-5,
#solutionsMarket .cls-67 {
	fill: none
}

#solutionsMarket .cls-3 {
	fill: #56a145
}

#solutionsMarket .cls-4 {
	fill: #6d655c
}

#solutionsMarket .cls-10,
#solutionsMarket .cls-5 {
	stroke: #b2b3b6
}

#solutionsMarket .cls-10,
#solutionsMarket .cls-233,
#solutionsMarket .cls-234,
#solutionsMarket .cls-5,
#solutionsMarket .cls-67 {
	stroke-linecap: round
}

#solutionsMarket .cls-105,
#solutionsMarket .cls-106,
#solutionsMarket .cls-45,
#solutionsMarket .cls-46,
#solutionsMarket .cls-5 {
	stroke-miterlimit: 10
}

#solutionsMarket .cls-5 {
	stroke-width: 10.23px
}

#solutionsMarket .cls-10,
#solutionsMarket .cls-164,
#solutionsMarket .cls-233,
#solutionsMarket .cls-234,
#solutionsMarket .cls-67 {
	stroke-linejoin: round
}

#solutionsMarket .cls-10,
#solutionsMarket .cls-67 {
	stroke-width: 3.41px
}

#solutionsMarket .cls-110,
#solutionsMarket .cls-158,
#solutionsMarket .cls-16 {
	fill: #27720a
}

#solutionsMarket .cls-104,
#solutionsMarket .cls-105,
#solutionsMarket .cls-110,
#solutionsMarket .cls-123,
#solutionsMarket .cls-157,
#solutionsMarket .cls-16,
#solutionsMarket .cls-17,
#solutionsMarket .cls-268,
#solutionsMarket .cls-454,
#solutionsMarket .cls-50,
#solutionsMarket .cls-52,
#solutionsMarket .cls-623,
#solutionsMarket .cls-71,
#solutionsMarket .cls-715 {
	mix-blend-mode: multiply
}

#solutionsMarket .cls-157,
#solutionsMarket .cls-16,
#solutionsMarket .cls-17,
#solutionsMarket .cls-454 {
	opacity: .45
}

#solutionsMarket .cls-17,
#solutionsMarket .cls-56,
#solutionsMarket .cls-623 {
	fill: #5d574f
}

#solutionsMarket .cls-18,
#solutionsMarket .cls-50 {
	fill: #231f20
}

#solutionsMarket .cls-19 {
	fill: #598fa6
}

#solutionsMarket .cls-20 {
	fill: #5c595a
}

#solutionsMarket .cls-21 {
	fill: #b2b3b6
}

#solutionsMarket .cls-22 {
	fill: #ae8658
}

#solutionsMarket .cls-23,
#solutionsMarket .cls-268 {
	fill: #6a3e17
}

#solutionsMarket .cls-24 {
	fill: #f1e4cd
}

#solutionsMarket .cls-25 {
	fill: #3f6c82
}

#solutionsMarket .cls-26 {
	fill: #cbae8a
}

#solutionsMarket .cls-27 {
	fill: #9b6930
}

#solutionsMarket .cls-28,
#solutionsMarket .cls-454 {
	fill: #a77b49
}

#solutionsMarket .cls-29 {
	fill: #cccccb
}

#solutionsMarket .cls-30 {
	fill: #929496
}

#solutionsMarket .cls-31 {
	fill: #75c0d5
}

#solutionsMarket .cls-32 {
	fill: #2d667d
}

#solutionsMarket .cls-33 {
	fill: #47abc7
}

#solutionsMarket .cls-35 {
	fill: #aac53a
}

#solutionsMarket .cls-36 {
	fill: #388f24
}

#solutionsMarket .cls-37 {
	fill: #aa1b1c
}

#solutionsMarket .cls-38 {
	fill: #367bb1
}

#solutionsMarket .cls-39 {
	fill: #fed437
}

#solutionsMarket .cls-40 {
	fill: #d39c3a
}

#solutionsMarket .cls-41 {
	fill: #9e6235
}

#solutionsMarket .cls-42 {
	fill: #ffefb5
}

#solutionsMarket .cls-43,
#solutionsMarket .cls-52 {
	fill: #754c28
}

#solutionsMarket .cls-105,
#solutionsMarket .cls-45 {
	stroke: #754c28
}

#solutionsMarket .cls-45,
#solutionsMarket .cls-46 {
	stroke-width: 1.5px
}

#solutionsMarket .cls-46 {
	stroke: #cbae8a
}

#solutionsMarket .cls-48 {
	fill: #443d34
}

#solutionsMarket .cls-50,
#solutionsMarket .cls-52,
#solutionsMarket .cls-71 {
	opacity: .25
}

#solutionsMarket .cls-51 {
	fill: #bb6528
}

#solutionsMarket .cls-53 {
	fill: #e8e9eb
}

#solutionsMarket .cls-54 {
	fill: #991b1c
}

#solutionsMarket .cls-55 {
	fill: #f16a24
}

#solutionsMarket .cls-57 {
	fill: #ff8a34
}

#solutionsMarket .cls-233,
#solutionsMarket .cls-67 {
	stroke: #2d667d
}

#solutionsMarket .cls-72 {
	fill: #4b297b
}

#solutionsMarket .cls-104,
#solutionsMarket .cls-110 {
	opacity: .55
}

#solutionsMarket .cls-105,
#solutionsMarket .cls-106,
#solutionsMarket .cls-164 {
	stroke-width: 1.32px
}

#solutionsMarket .cls-105,
#solutionsMarket .cls-106,
#solutionsMarket .cls-623 {
	opacity: .35
}

#solutionsMarket .cls-106 {
	stroke: #ae8658;
	mix-blend-mode: screen
}

#solutionsMarket .cls-113 {
	fill: #1f6ca8
}

#solutionsMarket .cls-114 {
	fill: #004060
}

#solutionsMarket .cls-123 {
	opacity: .65
}

#solutionsMarket .cls-153 {
	fill: #fff
}

#solutionsMarket .cls-155 {
	fill: #fbd50b
}

#solutionsMarket .cls-156 {
	fill: #b5b700
}

#solutionsMarket .cls-161 {
	fill: #004e74
}

#solutionsMarket .cls-162 {
	fill: #dbc6ad
}

#solutionsMarket .cls-164 {
	stroke: #eb008b
}

#solutionsMarket .cls-167 {
	fill: #d45100
}

#solutionsMarket .cls-186 {
	fill: #f68622
}

#solutionsMarket .cls-207 {
	fill: #00589c
}

#solutionsMarket .cls-217 {
	fill: #83af15
}

#solutionsMarket .cls-218 {
	fill: #6649b2
}

#solutionsMarket .cls-219 {
	fill: #4826a3
}

#solutionsMarket .cls-233,
#solutionsMarket .cls-234 {
	stroke-width: 2.64px
}

#solutionsMarket .cls-234 {
	stroke: #75c0d5
}

#solutionsMarket .cls-614 {
	fill: #1e5900
}

#solutionsMarket .cls-633 {
	fill: #83a83d
}

#solutionsMarket .cls-715 {
	opacity: .4
}

#solutionsMarket .cls-716,
#solutionsMarket .cls-716 tspan {
	font-size: 24px;
	fill: #014695;
	text-rendering: geometricprecision;
	font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
	font-weight: 500;
}

#solutionsMarket .cls-717 {
	fill: red
}

#solutionsMarket .cls-718 {
	filter: url(#luminosity-invert)
}



