

.doctorAnalytics .cls-1 {
	fill: #d0ecf3
}

.doctorAnalytics .cls-18,
.doctorAnalytics .cls-2,
.doctorAnalytics .cls-23,
.doctorAnalytics .cls-6 {
	fill: none
}

.doctorAnalytics .cls-2,
.doctorAnalytics .cls-6,
.doctorAnalytics .cls-8 {
	stroke: #929495
}

.doctorAnalytics .cls-11,
.doctorAnalytics .cls-2,
.doctorAnalytics .cls-23 {
	stroke-linejoin: round
}

.doctorAnalytics .cls-3 {
	fill: #47abc6
}

.doctorAnalytics .cls-23,
.doctorAnalytics .cls-3,
.doctorAnalytics .cls-4 {
	stroke: #fff
}

.doctorAnalytics .cls-23,
.doctorAnalytics .cls-3,
.doctorAnalytics .cls-4,
.doctorAnalytics .cls-6 {
	stroke-linecap: round
}

.doctorAnalytics .cls-18,
.doctorAnalytics .cls-3,
.doctorAnalytics .cls-4,
.doctorAnalytics .cls-6,
.doctorAnalytics .cls-8 {
	stroke-miterlimit: 10
}

.doctorAnalytics .cls-11,
.doctorAnalytics .cls-18,
.doctorAnalytics .cls-3,
.doctorAnalytics .cls-4,
.doctorAnalytics .cls-8 {
	stroke-width: 1.5px
}

.doctorAnalytics .cls-4 {
	fill: #377bb1
}

.doctorAnalytics .cls-11,
.doctorAnalytics .cls-5 {
	fill: #fff
}

.doctorAnalytics .cls-6 {
	stroke-width: 3px
}

.doctorAnalytics .cls-8 {
	fill: #cccccb
}

.doctorAnalytics .cls-11 {
	stroke: #75c0d5
}

.doctorAnalytics .cls-13 {
	fill: #ba9871
}

.doctorAnalytics .cls-16 {
	fill: #b3b3b6
}

.doctorAnalytics .cls-17 {
	fill: #929495
}

.doctorAnalytics .cls-18 {
	stroke: #cccccb
}

.doctorAnalytics .cls-19 {
	fill: #55a145
}

.doctorAnalytics .cls-20 {
	fill: #a91f23
}

.doctorAnalytics .cls-23 {
	stroke-width: 2px
}

