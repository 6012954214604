

.cloud-divider .cls-11,
.cloud-divider .cls-16 {
	fill: none;
	stroke-linecap: round;
}

.cloud-divider .cls-11,
.cloud-divider .cls-14,
.cloud-divider .cls-17 {
	stroke-miterlimit: 10;
}

.cloud-divider .cls-3 {
	fill: #fff;
	stroke: #adafb0;
}

.cloud-divider .cls-16,
.cloud-divider .cls-3 {
	stroke-linejoin: round;
}

.cloud-divider .cls-14,
.cloud-divider .cls-16,
.cloud-divider .cls-17,
.cloud-divider .cls-3 {
	stroke-width: 1.5px;
}

.cloud-divider .cls-4 {
	fill: #f68622;
}

.cloud-divider .cls-5 {
	fill: #47abc6;
}

.cloud-divider .cls-6 {
	fill: #aac538;
}

.cloud-divider .cls-8 {
	fill: #929495;
}

.cloud-divider .cls-9 {
	fill: #b3b3b6;
}

.cloud-divider .cls-11 {
	stroke: #a3d5e3;
}

.cloud-divider .cls-14,
.cloud-divider .cls-17 {
	stroke: #b3b3b6;
}

.cloud-divider .cls-13 {
	fill: #6d655c;
}

.cloud-divider .cls-14 {
	fill: #b6b2ae;
}

.cloud-divider .cls-16 {
	stroke: #e8e9eb;
}

.cloud-divider .cls-17 {
	fill: #d9d9db;
}

