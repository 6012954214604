/* START: FSAlbertArabic > Web_Fonts  */
@font-face {
    font-family: 'FSAlbertArabicRegular';
    src: local('FSAlbertArabicRegular'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Regular/FSAlbertArabicWeb-Regular.woff') format('woff'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Regular/FSAlbertArabicWeb-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'FSAlbertArabicThin';
    src: local('FSAlbertArabicThin'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Thin/FSAlbertArabicWeb-Thin.woff') format('woff'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Thin/FSAlbertArabicWeb-Thin.ttf') format('ttf');
}


@font-face {
    font-family: 'FSAlbertArabicLight';
    src: local('FSAlbertArabicLight'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Light/FSAlbertArabicWeb-Light.woff') format('woff'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Light/FSAlbertArabicWeb-Light.ttf') format('ttf');
}


@font-face {
    font-family: 'FSAlbertArabicBold';
    src: local('FSAlbertArabicBold'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Bold/FSAlbertArabicWeb-Bold.woff') format('woff'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/Bold/FSAlbertArabicWeb-Bold.ttf') format('ttf');
}


@font-face {
    font-family: 'FSAlbertArabicExtraBold';
    src: local('FSAlbertArabicExtraBold'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/ExtraBold/FSAlbertArabicWeb-ExtraBold.woff') format('woff'),
         url('./BD_FSAlbert_Fonts/FSAlbertArabic/Web_Fonts/ExtraBold/FSAlbertArabicWeb-ExtraBold.ttf') format('ttf');
}

/* END: FSAlbertArabic > Web_Fonts */