

.enterprise-doctor .cls-1 {
	fill: #75c0d5
}

.enterprise-doctor .cls-10,
.enterprise-doctor .cls-18,
.enterprise-doctor .cls-2 {
	fill: none
}

.enterprise-doctor .cls-3 {
	fill: #b7ce56;
	stroke: #55a145
}

.enterprise-doctor .cls-15,
.enterprise-doctor .cls-17,
.enterprise-doctor .cls-18,
.enterprise-doctor .cls-3,
.enterprise-doctor .cls-4,
.enterprise-doctor .cls-5 {
	stroke-linecap: round;
	stroke-miterlimit: 10
}

.enterprise-doctor .cls-12,
.enterprise-doctor .cls-15,
.enterprise-doctor .cls-3,
.enterprise-doctor .cls-4,
.enterprise-doctor .cls-9 {
	stroke-width: 1.5px
}

.enterprise-doctor .cls-15,
.enterprise-doctor .cls-4 {
	fill: #47abc6
}

.enterprise-doctor .cls-4 {
	stroke: #fff
}

.enterprise-doctor .cls-5 {
	fill: #c1222a;
	stroke: #e1212b
}

.enterprise-doctor .cls-7 {
	fill: #d0ecf3
}

.enterprise-doctor .cls-12,
.enterprise-doctor .cls-8,
.enterprise-doctor .cls-9 {
	fill: #fff
}

.enterprise-doctor .cls-9 {
	stroke: #adafb0
}

.enterprise-doctor .cls-10,
.enterprise-doctor .cls-12,
.enterprise-doctor .cls-9 {
	stroke-linejoin: round
}

.enterprise-doctor .cls-10,
.enterprise-doctor .cls-17 {
	stroke: #929495
}

.enterprise-doctor .cls-11 {
	fill: #6a3f18
}

.enterprise-doctor .cls-12 {
	stroke: #75c0d5
}

.enterprise-doctor .cls-14 {
	fill: #ba9871
}

.enterprise-doctor .cls-15 {
	stroke: #377bb1
}

.enterprise-doctor .cls-17 {
	fill: #929495
}

.enterprise-doctor .cls-18 {
	stroke: #e60c88
}

