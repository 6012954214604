

.pharmacy-doctor .cls-2,
.pharmacy-doctor .cls-7 {
	fill: #fff
}

.pharmacy-doctor .cls-2 {
	stroke: #adafb0
}

.pharmacy-doctor .cls-15,
.pharmacy-doctor .cls-2,
.pharmacy-doctor .cls-4,
.pharmacy-doctor .cls-5,
.pharmacy-doctor .cls-7,
.pharmacy-doctor .cls-9 {
	stroke-linejoin: round
}

.pharmacy-doctor .cls-2,
.pharmacy-doctor .cls-7 {
	stroke-width: 1.5px
}

.pharmacy-doctor .cls-3 {
	fill: #e1212b
}

.pharmacy-doctor .cls-4,
.pharmacy-doctor .cls-5 {
	fill: #cccccb;
	stroke: #929495
}

.pharmacy-doctor .cls-5 {
	fill: none
}

.pharmacy-doctor .cls-11 {
	fill: #f68622
}

.pharmacy-doctor .cls-16 {
	stroke-miterlimit: 10
}

.pharmacy-doctor .cls-7 {
	stroke: #75c0d5
}

.pharmacy-doctor .cls-8 {
	fill: #6d655c
}

.pharmacy-doctor .cls-9 {
	fill: #929495;
	stroke: #6d655c
}

.pharmacy-doctor .cls-10 {
	fill: #d3bb9c
}

.pharmacy-doctor .cls-15 {
	fill: #47abc6;
	stroke: #fff
}

.pharmacy-doctor .cls-16 {
	fill: #a3d5e3;
	stroke: #47abc6
}

